<template>
  <div class="booking-price-breakdown">
    <div class="booking-price-breakdown__columns">
      <div>
        <Title v-if="editPriceData" class="mb-24">Before editing</Title>
        <BookingPriceBreakdownTables
          :price-data="priceData"
          :players-count="playersCount"
          :pay-now="!editPriceData ? payNow : null"
          :is-before-edit="!!editPriceData"
          :product-name="productName"
        />
      </div>
      <div v-if="editPriceData">
        <Title class="mb-24">After editing</Title>
        <BookingPriceBreakdownTables
          :price-data="editPriceData"
          :players-count="editPlayersCount"
          :pay-now="payNow"
          is-edit
        />
      </div>
    </div>
  </div>
</template>

<script>
import BookingPriceBreakdownTables from "@/components/bookings/BookingPriceBreakdownTables.vue";

export default {
  name: "BookingPriceBreakdown",
  components: { BookingPriceBreakdownTables },
  props: {
    priceData: {
      type: Object,
      default: null,
    },
    editPriceData: {
      type: Object,
      default: null,
    },
    playersCount: {
      type: Number,
      default: 1,
    },
    editPlayersCount: {
      type: [Number, null],
      default: null,
    },
    payNow: {
      type: Number,
      default: 0,
    },
    productName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.booking-price-breakdown {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__columns {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      gap: 0;
    }

    > * {
      flex: 1;
    }
  }
}
</style>
