<template>
  <div class="booking-price-breakdown-tables">
    <div
      v-for="(table, index) in [bookingTableDefinition, balanceTableDefinition]"
      :key="index"
      class="booking-price-breakdown-tables__section"
    >
      <div class="booking-price-breakdown-tables__section__title-row">
        <Title :level="2">{{ table.title }}</Title>
        <Icon
          v-if="!isBeforeEdit && !isEdit"
          :name="isCollapsedState[index] ? 'plus' : 'minus'"
          color="secondary-400"
          :size="20"
          :isClickable="true"
          @click="toggleCollapseState(index)"
        />
      </div>
      <VueGoodTable
        v-show="!isCollapsedState[index]"
        :columns="table.columns"
        :rows="table.rows"
        :sort-options="{
          enabled: false,
        }"
        :bordered="false"
        :class="table.class"
        :row-style-class="rowStyleClassFn"
      >
        <template #table-row="props">
          <template v-if="props.row.perPerson && props.row.quantity">
            <span v-if="props.column.field === 'multiply'">
              <Icon name="close" color="secondary-400" :size="8" />
            </span>
            <span v-else-if="props.column.field === 'equal'">
              <Icon name="equal" color="secondary-400" :size="8" />
            </span>
          </template>
          <span v-else :class="props.column.isColor && props.row.colorClass">
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </VueGoodTable>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { mapState } from "vuex";

export default {
  name: "BookingPriceBreakdownTables",
  components: {
    VueGoodTable,
  },
  props: {
    priceData: {
      type: Object,
      default: null,
    },
    playersCount: {
      type: Number,
      default: 1,
    },
    payNow: {
      type: Number,
      default: 0,
    },
    isEdit: {
      type: Boolean,
    },
    isBeforeEdit: {
      type: Boolean,
    },
    productName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCollapsedState: [],
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
    bookingColumns() {
      return [
        !this.isEdit && {
          label: "",
          field: "rowName",
        },
        {
          label: `Per Person${
            this.priceData?.upsellItems?.length ? "/Item" : ""
          }`,
          field: "perPerson",
          isColor: true,
          thClass: this.isEdit && "m-left-padding",
          tdClass: this.isEdit && "m-left-padding",
        },
        {
          label: "",
          field: "multiply",
          thClass: "m-center",
          tdClass: "m-center",
        },
        {
          label: "Quantity",
          field: "quantity",
          thClass: "m-center",
          tdClass: "m-center",
        },
        {
          label: "",
          field: "equal",
          tdClass: "m-center",
        },
        {
          label: "Subtotal",
          field: "subtotal",
          thClass: ["m-right", this.isBeforeEdit && "m-right-padding"]
            .filter(Boolean)
            .join(" "),
          tdClass: ["m-right", this.isBeforeEdit && "m-right-padding"]
            .filter(Boolean)
            .join(" "),
          isColor: true,
        },
      ].filter(Boolean);
    },
    balanceColumns() {
      return [
        {
          label: "",
          field: "rowName",
          tdClass: this.isEdit && "m-left-padding",
        },
        {
          label: "",
          field: "value",
          tdClass: ["m-right", this.isBeforeEdit && "m-right-padding"]
            .filter(Boolean)
            .join(" "),
          isColor: true,
        },
      ].filter(Boolean);
    },
    bookingTableDefinition() {
      const quantity = this.playersCount;
      const rows = [
        {
          rowName: `${this.productName || "Product"}:`,
          isSubsectionTitle: true,
        },
        {
          rowName: "Price:",
          perPerson: this.formatMoney(
            this.priceData?.subtotal / quantity / 100
          ),
          quantity: quantity || "-",
          subtotal: this.formatMoney(this.priceData?.subtotal / 100),
          isTabbed: true,
        },
        ...(this.priceData?.taxes?.map((tax) => ({
          rowName: `${tax.name}:`,
          perPerson: this.formatMoney(tax.amount / quantity / 100),
          quantity: quantity || "-",
          subtotal: this.formatMoney(tax.amount / 100),
          isTabbed: true,
        })) || []),
        this.priceData?.upsellItems?.length && {
          rowName: "Subtotal:",
          perPerson: this.formatMoney(
            this.subtotalWithTaxesAmount / quantity / 100
          ),
          quantity: quantity || "-",
          subtotal: this.formatMoney(this.subtotalWithTaxesAmount / 100),
          isTabbed: true,
          isTotal: true,
        },
        this.priceData?.upsellItems?.length && {
          rowName: "Upsell merchandise:",
          isSubsectionTitle: true,
        },
        ...(this.priceData?.upsellItems?.map((item) => ({
          rowName: `${item.name}:`,
          perPerson: this.formatMoney(item.amount / quantity / 100),
          quantity: item.quantity || "-",
          subtotal: this.formatMoney(item.amount / 100),
          isTabbed: true,
        })) || []),
        ...(this.priceData?.upsellTaxes?.map((tax) => ({
          rowName: `${tax.name}:`,
          subtotal: this.formatMoney(tax.amount / 100),
          isTabbed: true,
        })) || []),
        this.priceData?.upsellItems?.length && {
          rowName: "Subtotal:",
          subtotal: this.formatMoney(this.upsellWithTaxesAmount / 100),
          isTabbed: true,
          isTotal: true,
        },
        this.priceData?.discounts?.length && {
          rowName: "Discounts:",
          isSubsectionTitle: true,
        },
        ...(this.priceData?.discounts?.map((item) => ({
          rowName: `${item.name}:`,
          perPerson: this.formatMoney(item.amount / quantity / 100),
          quantity: quantity || "-",
          subtotal: this.formatMoney(item.amount / 100),
          colorClass: "m-green",
          isTabbed: true,
        })) || []),
        {
          rowName: "Total:",
          ...(!this.priceData?.upsellItems?.length
            ? {
                perPerson: this.formatMoney(
                  this.priceData?.total / quantity / 100
                ),
                quantity: quantity || "-",
              }
            : {}),
          subtotal: this.formatMoney(this.priceData?.total / 100),
          isTotal: true,
        },
      ].filter(Boolean);
      return {
        rows,
        columns: this.bookingColumns,
        title: !this.isEdit ? "Booking" : "",
      };
    },
    balanceTableDefinition() {
      const rows = [
        {
          rowName: !this.isEdit ? "Paid:" : "",
          value: this.formatMoney((this.priceData?.paid || 0) / 100),
        },
        this.priceData?.refunded && {
          rowName: !this.isEdit ? "Refunded:" : "",
          value: this.formatMoney(-this.priceData.refunded / 100),
        },
        {
          rowName: !this.isEdit ? "Number of deposited persons:" : "",
          value: this.numberOfDepositedPersons || 0,
          colorClass: "m-green",
        },
        this.paymentDue && {
          rowName: "Payment due:",
          value: this.formatMoney(this.paymentDue / 100),
          colorClass: "m-blue",
        },
        this.payNow && {
          rowName: "Due now:",
          value: this.formatMoney(this.payNow / 100),
          colorClass: "m-red",
        },
      ].filter(Boolean);
      return {
        rows,
        columns: this.balanceColumns,
        title: !this.isEdit ? "Balance" : "",
        class: "m-headless",
      };
    },
    numberOfDepositedPersons() {
      return this.priceData?.paid
        ? Math.max(
            0,
            Math.round(
              ((this.playersCount *
                (this.priceData.paid -
                  this.priceData.refunded -
                  this.upsellWithTaxesAmount)) /
                (this.priceData.total - this.upsellWithTaxesAmount)) *
                100
            ) / 100
          )
        : "-";
    },
    upsellWithTaxesAmount() {
      return [
        ...(this.priceData?.upsellItems || []),
        ...(this.priceData?.upsellTaxes || []),
      ]
        .filter(Boolean)
        .reduce((prev, item) => prev + item.amount, 0);
    },
    subtotalWithTaxesAmount() {
      if (!this.priceData) {
        return 0;
      }
      return (
        this.priceData.subtotal +
        (this.priceData.taxes?.reduce((prev, item) => prev + item.amount, 0) ||
          0)
      );
    },
    paymentDue() {
      if (this.priceData) {
        const result =
          this.priceData.total -
          (this.priceData.paid || 0) +
          (this.priceData.refunded || 0) -
          (this.payNow || 0);
        if (result > 0) {
          return result;
        }
      }
      return null;
    },
  },
  methods: {
    formatMoney(amount) {
      return amount || amount === 0
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(amount)
        : "-";
    },
    toggleCollapseState(index) {
      const copy = [...this.isCollapsedState];
      copy[index] = !copy[index];
      this.isCollapsedState = copy;
    },
    rowStyleClassFn(row) {
      return [
        row.isSubsectionTitle && "m-subsection-title",
        row.isTabbed && "m-tabbed",
        row.isTotal && "m-total",
      ]
        .filter(Boolean)
        .join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.booking-price-breakdown-tables {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__section {
    &__title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      height: 24px;
    }

    ::v-deep {
      .vgt-inner-wrap {
        box-shadow: none;
      }
      .vgt-table {
        background: transparent;
        border: none;

        tr {
          height: 34px;

          &:first-child {
            td,
            th {
              border-top: none;
            }
          }
          &:last-child {
            td,
            th {
              border-bottom: 1px solid #cdcdcd;
            }
          }
          &.m-total {
            td,
            th {
              border-top-color: $secondary-500;
            }
          }

          &.m-subsection-title {
            background: $secondary-300;
          }
          &.m-tabbed {
            td:first-child {
              padding-left: 24px;
            }
          }
        }

        th,
        td {
          border: none;
          background: transparent;
          padding: 6px 0;
          font-size: 14px;
          font-style: normal;
          line-height: 140%;
          color: #262626;
          white-space: nowrap;

          border-top: 1px solid #cdcdcd;

          &.m-center {
            text-align: center;
          }
          &.m-right {
            text-align: right;
          }

          &.m-right-padding {
            padding-right: 40px;
          }
          &.m-left-padding {
            padding-left: 40px;
            border-left: 1px solid #cdcdcd;
          }

          .m-blue {
            color: $primary;
          }
          .m-green {
            color: $success;
          }
          .m-red {
            color: $danger;
          }
        }

        th,
        td:first-child,
        td:last-child {
          font-weight: 500;
        }
      }

      .m-headless {
        thead {
          display: none;
        }
      }
    }
  }
}
</style>
